import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const apiService = axios.create({
  baseURL: API_BASE_URL,
});

export const faqCategorieFilter = async (id) => {
  try {
    const response = await apiService.get(`get-category-faqs/${id}`);
    return response.data;
  } catch (error) {}
};

export const faqCategoriesDropdown = async () => {
  try {
    const response = await apiService.get("/faq-categories-dropdown");
    return response.data;
  } catch (error) {}
};

//product details

export const addToCart = async (token, id, productVarientId) => {
  try {
    if (token) {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const body = {
        product_id: id,
        quantity: 1,
        variant_id: productVarientId,
        attribute_id: 0,
      };
      const response = await apiService.post("/add-to-cart", body, config);
      return response.data;
    } else {
      return {
        status: 401,
        message: "Please Login First",
      };
    }
  } catch (error) {
    throw error;
  }
};
export const addToWishList = async (token, id) => {
  try {
    if (token) {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const body = {
        product_id: id,
      };
      const response = await apiService.post("/add-wishlist", body, config);
      return response.data;
    } else {
      return {
        status: 401,
        message: "Please Login First",
      };
    }
  } catch (error) {
    return 403;
  }
};
export const getImageVarients = async (id) => {
  try {
    const response = await apiService.get(`/get-product-variant/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getProductImages = async (id) => {
  try {
    const response = await apiService.get(`/get-product-assets/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getSingleProduct = async (id) => {
  try {
    const response = await apiService.get(`/get-product/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const preOrder = async (token, id, productVarientId) => {
  try {
    if (token) {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const body = {
        product_id: id,
        quantity: 1,
        variant_id: productVarientId,
        attribute_id: 0,
      };
      const response = await apiService.post(
        "/add-pre-order-cart",
        body,
        config
      );
      return response.data;
    } else {
      return {
        status: 401,
        message: "Please Login First",
      };
    }
  } catch (error) {
    throw error;
  }
};

//product details

export const getSimilarProduct = async (id) => {
  try {
    const response = await apiService.get(`/get-similar-products/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllProducts = async (value) => {
  try {
    const response = await apiService.get(`/get-shop-products/${value}`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCategoriesOptionsAPICall = async () => {
  try {
    const response = await apiService.get("/dropdown-sub-categories");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBrandOptionsAPICall = async () => {
  try {
    const response = await apiService.get("/all-brands");
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getFilterProductsAPICall = async (
  CategoryID,
  BrandaID,
  minPrice,
  maxPrice
) => {
  try {
    const response = await apiService.get(
      `get-filter-products/${CategoryID}/${BrandaID}/${minPrice}/${maxPrice}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const priceFilterRangeGetAPICall = async () => {
  try {
    const response = await apiService.get("/price-range-data");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const loginApi = async (loginDetails) => {
  try {
    const response = await apiService.post("/login", loginDetails);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const registerApi = async (registerData) => {
  try {
    const response = await apiService.post("/register", registerData);
    return response.data;
  } catch (error) {}
};
export const checkOtp = async (payload) => {
  try {
    const response = await apiService.post("/check-otp", payload);
    return response.data;
  } catch (error) {}
};

export const getGlobalSearchAPICall = async (searchTerm) => {
  try {
    const response = await apiService.get(`/get-global-search/${searchTerm}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const reSendOtp = async (payload) => {
  try {
    const reSendOtpData = {
      email: payload,
    };
    const response = await apiService.post("/resend-otp", reSendOtpData);
    return response.data;
  } catch (error) {}
};

export const CategoryMainDropdown = async () => {
  try {
    const response = await apiService.get("/dropdown-main-categories");
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const CategorySubDropdown = async () => {
  try {
    const response = await apiService.get("/dropdown-sub-categories");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCategoryProductsAPICall = async (productID) => {
  try {
    const response = await apiService.get(`get-category-products/${productID}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const forgotPasswordApi = async (forgotEmail) => {
  try {
    const forgotPasswordData = {
      email: forgotEmail,
    };
    const response = await apiService.post(
      "/forget-password",
      forgotPasswordData
    );
    return response;
  } catch (error) {}
};
export const getBlogImageList = async () => {
  try {
    const response = await apiService.get("/get-blogs");
    return response.data;
  } catch (error) {}
};
export const getCategoryList = async () => {
  try {
    const response = await apiService.get("/blog-categories-dropdown");
    return response.data;
  } catch (error) {}
};
export const getRecentBlogs = async () => {
  try {
    const response = await apiService.get("/recent-blogs");
    return response.data;
  } catch (error) {}
};
export const getTagsList = async () => {
  try {
    const response = await apiService.get("/blog-tags-dropdown");
    return response.data;
  } catch (error) {}
};
export const getOneBlogDetails = async (id) => {
  try {
    const response = await apiService.get(`/get-single-blog/${id}`);
    return response.data;
  } catch (error) {}
};
export const filterCategoryBlogs = async (id) => {
  try {
    const response = await apiService.get(`/get-category-blogs/${id}`);
    return response.data;
  } catch (error) {}
};
export const tagFilterBlogs = async (id) => {
  try {
    const resposne = await apiService.get(`/get-tag-blogs/${id}`);
    return resposne.data;
  } catch (error) {}
};
export const updateCartItems = async (token, payload) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await apiService.post("update-to-cart", payload, config);
    return response.data;
  } else {
    return {
      status: 401,
      message: "Please Login First!",
    };
  }
};

export const getCartTotal = async (token) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await apiService.get("/get-cart-total", config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
};
export const getAllCartProducts = async (token) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await apiService.get("/get-user-cart", config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
};

export const getAllcoupons = async (token) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await apiService.get("/all-coupons", config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
};

export const applyCouponCode = async (token, coupon) => {
  try {
    if (token) {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const body = {
        coupon_code: coupon,
      };
      const response = await apiService.post("/apply-coupon", body, config);
      return response.data;
    } else {
      throw new Error(401);
    }
  } catch (error) {
    throw error;
  }
};

export const saveBillingAndShippingAddressAPICall = async (token, payload) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await apiService.post(
      "store-billing-shipping-address",
      payload,
      config
    );
    return response.data;
  } else {
    return {
      status: 401,
      message: "Please Login First!",
    };
  }
};

export const stateList = async (id) => {
  try {
    const response = await apiService.get(`/get-states/${id}`);
    return response.data;
  } catch (error) {}
};
export const cityList = async (id) => {
  try {
    const response = await apiService.get(`/get-cities/${id}`);
    return response.data;
  } catch (error) {}
};
export const countryList = async () => {
  try {
    const response = await apiService.get("/get-countries");
    return response.data;
  } catch (error) {}
};
export const pageListData = async (token, pageId) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await apiService.get(`/get-page-data/${pageId}`, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    try {
      const response = await apiService.get(`/get-page-data/${pageId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
};
export const getAllWishListProducts = async (token) => {
  try {
    if (token) {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await apiService.get("/get-user-whishlist", config);
      return response.data;
    }
  } catch (error) {}
};

export const createWishList = async (payload, token) => {
  try {
    if (token) {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const postData = {
        product_id: payload,
      };
      const response = await apiService.post("/add-wishlist", postData, config);
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};
export const submitContactUs = async (contactDetails) => {
  try {
    const response = await apiService.post(
      "/submit-contact-us",
      contactDetails
    );
    return response.data;
  } catch (error) {}
};
export const removeWishlist = async (id, token) => {
  try {
    if (token) {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const payload = {
        product_id: id,
      };
      const response = await apiService.post(
        "/remove-wishlist",
        payload,
        config
      );
      return response.data;
    }
  } catch (error) {}
};

export const getShippingAddressAPICall = async (token) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await apiService.get("get-shipping-addresses", config);
    return response.data;
  } else {
    return {
      status: 401,
      message: "Please Login First!",
    };
  }
};
export const getBillingAddressAPICall = async (token) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await apiService.get("get-billing-addresses", config);
    return response.data;
  } else {
    return {
      status: 401,
      message: "Please Login First!",
    };
  }
};

export const getCartAmountListAPICall = async (token) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await apiService.get("get-cart-total", config);
    return response.data;
  } else {
    return {
      status: 401,
      message: "Please Login First!",
    };
  }
};
export const SettingApiData = async () => {
  try {
    const response = await apiService.get("/settings-data");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const FullLinksData = async () => {
  try {
    const response = await apiService.get("/get-sub-pages");
    return response.data;
  } catch (error) {}
};
export const fulLinkDataDetails = async (id) => {
  try {
    const response = await apiService.get(`/sub-page-detail/${id}`);
    return response.data;
  } catch (error) {}
};
export const sentSpinnerCouponAPICall = async (token, payload) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await apiService.post("/spinner-win", payload, config);
    return response.data;
  } else {
    return {
      status: 401,
      message: "Please Login First!",
    };
  }
};
export const fetchUserName = async (token) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await apiService.get("user", config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
};

export const logoutService = async (token) => {
  if (token) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await apiService.get("logout", config);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
};
export const fetchUserData = async (token) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await apiService.get("/user", config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
};

export const placeOrderApiCall = async (payload, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await apiService.post("/place-order", payload, config);
    return response.data;
  } catch (error) {}
};
export const getOrderList = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await apiService.get("/my-order-list", config);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const viewOrderDetails = async (id, token) => {
  try {
    const config = {
      headers :{
        Authorization: `Bearer ${token}`
      }
    }
    const response = await apiService.get(`/my-order-detail/${id}`, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const orderReturnRequest = async (orderReturnRequestCode, token) => {

  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    try {
      const body = {
        order_code: orderReturnRequestCode,
      }
      const response = await apiService.post('/order-return-request',  body , config);
      return response.data;
      
    } catch (error) {
      console.error('Error cancelling order:', error);
      throw error; 
    }
  } 
};
export const cancelOrder = async (orderCode, token) => {

  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    try {
      const body = {
        order_code: orderCode,
      }
      const response = await apiService.post('/my-order-cancel',  body , config);
      return response.data;
      
    } catch (error) {
      console.error('Error cancelling order:', error);
      throw error; 
    }
  } 
};
export const deleteApiCartItems = async (payload, token) => {
  if (token) {
    try {

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,

        },
      };
      const response = await apiService.post('remove-add-to-cart-product', payload, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
};
export const getCampaignList = async () => {
  try {
    const response = await apiService.get("/get-campaign-page/1");
    return response.data;
  } catch (error) {
    throw error;
  }
};