import React, { useEffect, useState } from "react";
import "../../assets/css/lazyloader.css";

const LazyLoader = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <div id="preloader-background">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      width="200"
      height="200"
      style={{ shapeRendering: "auto", display: "block" }}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        {Array.from({ length: 12 }).map((_, index) => (
          <g key={index} transform={`rotate(${index * 30} 50 50)`}>
            <rect
              fill="#0aaef5"
              height="12"
              width="6"
              ry="6"
              rx="3"
              y="24"
              x="47"
            >
              <animate
                repeatCount="indefinite"
                begin={`-${
                  2.6315789473684212 - index * 0.21929824561403508
                }s`}
                dur="2.6315789473684212s"
                keyTimes="0;1"
                values="1;0"
                attributeName="opacity"
              ></animate>
            </rect>
          </g>
        ))}
      </g>
    </svg>
  </div>
  );
};

export default LazyLoader;
