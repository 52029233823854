import React, { useEffect, useState } from "react";
import Product1 from "../../assets/images/shop/large/product1.png";
import Product2 from "../../assets/images/shop/large/product2.png";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";

const Adbanner = ({ sections }) => {
  const AppUrl = process.env.REACT_APP_IMAGE_URL;

  const section = sections?.find((item) => item.key_name === "CAMP");
  const campaign = section?.campaign_data?.[0];
  const expireDate = campaign ? campaign.expire_date : null;
  const banner = campaign ? campaign.image_path : null;
  const title = sections.find((item) => item.key_name === "TITLE_1");
  const [countdown, setCountdown] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });
  const navigate = useNavigate();
  useEffect(() => {
    if (!expireDate) return;

const newExprieDateFormat = moment(expireDate,'DD-MM-YYYY').format("DD-MM-YYYY")
    const countdownDate = moment(newExprieDateFormat, "DD-MM-YYYY").endOf('day').valueOf();
    const interval = setInterval(() => {
      const now = moment().valueOf(); 
      const distance = countdownDate - now;
      if (distance < 0) {
        clearInterval(interval);
        setCountdown({
          days: "00",
          hours: "00",
          minutes: "00",
          seconds: "00",
        });
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setCountdown({
          days: String(days).padStart(2, "0"),
          hours: String(hours).padStart(2, "0"),
          minutes: String(minutes).padStart(2, "0"),
          seconds: String(seconds).padStart(2, "0"),
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [expireDate]);

  if (!campaign) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <section className="adv-area">
        <div className="container-fluid px-0 ">
          <div
            className="row product-style2 g-0"
            onClick={() => navigate("/campage-list")}
          >
            <div
              className="product-media d-flex"
              style={{
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundImage: `url(${AppUrl + banner})`,
              }}
            >
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="product-box style-4">
                  <div className="sale-box d-flex flex-column justify-content-start w-100 h-100">
                    <div className="sale-name w-50 mt-5 fs-4 text-center text-md-center">
                      {campaign.campaign_name}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12 d-flex flex-column align-items-center justify-content-center">
                <div className="product-content">
                  <div className="main-content">
                    <h2 className="product-name d-flex">
                      <div className="d-flex flex-column align-items-center me-3">
                        <span
                          className="label-text fs-4 fw-bold"
                          style={{
                            backgroundColor: "#f8f9fa",
                            color: "#000000",
                            padding: "0.2rem 0.5rem",
                            borderRadius: "0.25rem",
                            fontSize: "0.875rem",
                            fontWeight: "500",
                          }}
                        >
                          Days
                        </span>
                        <span
                          className="btn border text-white fw-semibold fs-2"
                          style={{
                            background:
                              "linear-gradient(135deg, #ff6f61, #d84a4a)",
                            borderColor: "transparent",
                            borderRadius: "0.5rem",
                            padding: "0.75rem 1.5rem",
                            margin: "0.25rem",
                            fontSize: "2rem",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          {countdown.days}
                        </span>
                      </div>
                      <div className="d-flex flex-column align-items-center me-3">
                        <span
                          className="label-text fs-4 fw-bold"
                          style={{
                            backgroundColor: "#f8f9fa",
                            color: "#000000",
                            padding: "0.2rem 0.5rem",
                            borderRadius: "0.25rem",
                            fontSize: "0.875rem",
                            fontWeight: "500",
                          }}
                        >
                          Hours
                        </span>
                        <span
                          className="btn border text-white fw-semibold fs-2"
                          style={{
                            background:
                              "linear-gradient(135deg, #ff6f61, #d84a4a)",
                            borderColor: "transparent",
                            borderRadius: "0.5rem",
                            padding: "0.75rem 1.5rem",
                            margin: "0.25rem",
                            fontSize: "2rem",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          {countdown.hours}
                        </span>
                      </div>
                      <div className="d-flex flex-column align-items-center me-3">
                        <span
                          className="label-text fs-4 fw-bold"
                          style={{
                            backgroundColor: "#f8f9fa",
                            color: "#000000",
                            padding: "0.2rem 0.5rem",
                            borderRadius: "0.25rem",
                            fontSize: "0.875rem",
                            fontWeight: "500",
                          }}
                        >
                          Minutes
                        </span>
                        <span
                          className="btn border text-white fw-semibold fs-2"
                          style={{
                            background:
                              "linear-gradient(135deg, #ff6f61, #d84a4a)",
                            borderColor: "transparent",
                            borderRadius: "0.5rem",
                            padding: "0.75rem 1.5rem",
                            margin: "0.25rem",
                            fontSize: "2rem",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          {countdown.minutes}
                        </span>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <span
                          className="label-text fs-4 fw-bold"
                          style={{
                            backgroundColor: "#f8f9fa",
                            color: "#000000",
                            padding: "0.2rem 0.5rem",
                            borderRadius: "0.25rem",
                            fontSize: "0.875rem",
                            fontWeight: "500",
                          }}
                        >
                          Seconds
                        </span>
                        <span
                          className="btn border text-white fw-semibold fs-2"
                          style={{
                            background:
                              "linear-gradient(135deg, #ff6f61, #d84a4a)",
                            borderColor: "transparent",
                            borderRadius: "0.5rem",
                            padding: "0.75rem 1.5rem",
                            margin: "0.25rem",
                            fontSize: "2rem",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          {countdown.seconds}
                        </span>
                      </div>
                    </h2>
                  </div>

                  <a className="btn btn-secondary btn-lg text-uppercase">
                    Shop Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Adbanner;
