import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const PaginationIndex = ({ totalProducts, productsPerPage, currentPage, handlePageChange, handleNextPage, handlePreviousPage,gotoFirstPage }) => {
  const totalPages = Math.ceil(totalProducts / productsPerPage);
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }


  return (
    <div className="row pgntn_par">

      <div className="d-flex align-items-center justify-content-center" >
        <div className="d-flex align-items-center me-3 " role="button"  onClick={gotoFirstPage}>
        <FontAwesomeIcon icon={faAngleDoubleLeft} size='sm' color="	#A8A8A8"/>
          <span className="text-secondary ps-2">page 1</span>
        </div>
        <div className="page_number">
          <Link
            className="prev"
            onClick={handlePreviousPage}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
            <span className="ps-2">Previous</span>
          </Link>
          <div >
            <div className="page_count">
              Page {currentPage} of {totalPages}
            </div>
          </div>
          <Link
            className="next"
            onClick={handleNextPage}>
            <span className="pe-2">Next</span>
            <FontAwesomeIcon icon={faChevronRight} />

          </Link>
        </div>
      </div>

    </div>
  );
};

export default PaginationIndex;
